




































































































































































































































































































































import { computed, defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import moment from 'moment'
import { debounce, delay, orderBy } from 'lodash'
import { api, framebus } from 'plugins'

import { useExpansion, useQuery, useShowError } from 'hooks'
import {
  convertToWidthCharacter,
  endpoints,
  frameBusEvent,
  groupMultiLevel,
  mappingStock,
  query,
  showError,
  toCamelCase,
  urlPath,
  groupTwoLevel,
  HARVEST_RESULT_DES
} from 'utils'
import {
  HarvestProductInfo,
  HarvestResult as IHarvestResult,
  MultiLevelProduct,
  Quality,
  Size,
  Variety
} from 'typings'

import {
  AddButton,
  LoaderComponent,
  MultiLevelItem,
  MultiLevelList,
  MultiLevelListJsx,
  SingleDetailItem,
  SettingViewDialog,
  DateRange,
  ConfirmDelete
} from 'components'
import OrderDialog from './OrderDialog.vue'

interface SelectedLevel {
  rootIndex: number | null
  firstIndex: number | null
  secondIndex: number | null
}

const HarvestResult = defineComponent({
  components: {
    AddButton,
    LoaderComponent,
    MultiLevelList,
    MultiLevelItem,
    OrderDialog,
    MultiLevelListJsx,
    SingleDetailItem,
    SettingViewDialog,
    DateRange,
    ConfirmDelete
  },
  setup(props, { root }) {
    const { $toast, $router, $route, $store } = root
    const { show_by: showByValue, search_info: searchInfoValue } = $route.query
    const isFromCalendar = $route.query.fromCalendar
    // check in store, if data available then get auction_date, packing_range from store
    const date = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.auction_date || moment(new Date()).format('YYYY-MM-DD')
    )
    const dateFrom = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[0]
        : $route.query.date_from || moment(new Date()).format('YYYY-MM-DD')
    )
    const dateTo = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[1]
        : $route.query.date_to || moment(new Date()).format('YYYY-MM-DD')
    )
    // if go to page from calendar page, get date from url
    if (isFromCalendar) {
      date.value = $route.query.auction_date
      dateFrom.value = $route.query.date_from
      dateTo.value = $route.query.date_to
    }

    const hideButtons = ['expected_harvest', 'FM']

    const allowSearchLoading = ref(false)

    const initDateFrom =
      (dateFrom as string) || $route.query.startDate || moment().format('YYYY-MM-DD')
    const initDateTo = (dateTo as string) || $route.query.endDate || moment().format('YYYY-MM-DD')

    const harvestDateFrom = ref(initDateFrom)
    const harvestDateFromModal = ref(false)

    const harvestDateTo = ref(initDateTo)
    const harvestDateToModal = ref(false)

    const dateRange = ref([initDateFrom, initDateTo])
    dateRange.value = [harvestDateFrom.value, harvestDateTo.value]

    const auctionDateModal = ref(false)

    const showBottomSheet = ref(false)
    const showOrderDialog = ref(false)
    const searchInfo = ref((searchInfoValue as string) || '')
    const selectedHarvestId = ref(null)
    const isShowSetting = ref(false)
    const isShowByBox = ref(false)
    const isShowTotal = ref(true)
    const isShowRemaining = ref(false)
    const showDelete = ref(false)

    const nextAuctionDate = ref('')

    const assignments = ref([])
    const treeviewLevel = ref(
      Number(
        $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
      ) || 4
    )

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        nextAuctionDate.value = data.data.date
        date.value = nextAuctionDate.value as string
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const getSettingDefault = async (): Promise<void> => {
      try {
        const response = await api.get(`${endpoints.COMMON}get_setting`)
        const settingMember = response.data.setting.harvest
        isShowByBox.value = settingMember?.is_show_by_box || false
        isShowTotal.value = settingMember ? settingMember.is_show_total : true
        isShowRemaining.value = settingMember?.is_show_remaining || false
      } catch (e) {
        showError(e, $toast, root.$t('setting.error.get_default_setting_fail') as string)
      }
    }
    const getSettingLevel = async (): Promise<void> => {
      treeviewLevel.value =
        Number(
          $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
        ) || 4
    }

    const updateSetting = (
      isShowTotalValue: boolean,
      isShowByBoxValue: boolean,
      isShowRemainingValue: boolean
    ) => {
      isShowByBox.value = isShowByBoxValue
      isShowTotal.value = isShowTotalValue
      isShowRemaining.value = isShowRemainingValue
      isShowSetting.value = false
      // update state
      $store.commit('setShowValueHarvest', {
        isShowByBox: isShowByBoxValue,
        isShowTotal: isShowTotalValue,
        isShowRemaining: isShowRemainingValue
      })
    }

    const selectedLevel = reactive<SelectedLevel>({
      rootIndex: null,
      firstIndex: null,
      secondIndex: null
    })

    const panelState = computed(() => $store.state.harvest.panelState)
    const productPanelState = computed(() => $store.state.harvest.productPanelState)

    const showBy = ref((showByValue as string) || 'HARVEST')
    const selectItems = [
      { name: root.$t('harvest.title') as string, key: 'HARVEST' },
      { name: root.$t('product') as string, key: 'PRODUCT' },
      { name: root.$t('history') as string, key: 'HISTORY' }
    ]

    const { getExpandPanelState, getCollapsePanelState } = useExpansion()

    const queryStr = computed(() => {
      return query.buildQuery({
        start_date: harvestDateFrom.value,
        end_date: harvestDateTo.value
      })
    })

    const url = computed(() => {
      return `${endpoints.HARVEST_RESULT}?${queryStr.value}`
    })

    const endPoint = ref(url.value)

    const { data: harvestResults, error, loading, isValidating } = useQuery(() => endPoint.value)
    useShowError($toast, error)

    const {
      data: varieties,
      error: varietiesError,
      loading: varietyLoading
    } = useQuery(`${endpoints.VARIETIES}?with_image=false`)
    useShowError($toast, varietiesError)

    const { data: sizes, error: sizeError, loading: sizeLoading } = useQuery(endpoints.SIZES)
    useShowError($toast, sizeError)
    const {
      data: qualities,
      error: qualityError,
      loading: qualityLoading
    } = useQuery(endpoints.QUALITIES)
    useShowError($toast, qualityError)

    const allowProductLoading = ref(false)

    const productLoading = computed(() => {
      return (
        varietyLoading.value ||
        sizeLoading.value ||
        qualityLoading.value ||
        allowProductLoading.value
      )
    })

    const assignUrl = computed(() => {
      return `${endpoints.ASSIGNMENTS}?auction_date=${date.value}`
    })
    const assignEndPoint = ref('')
    // const { data: assignments, error: assignError } = useQuery(() => assignEndPoint.value)
    // useShowError($toast, assignError)

    const getAssignments = async () => {
      try {
        const { data } = await api.get(assignUrl.value)
        assignments.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const packingUrl = computed(() => {
      return `${endpoints.PACKING_RESULTS}get_packing_result_by_range?${queryStr.value}`
    })

    const packingEndPoint = ref(packingUrl.value)
    const { data: packingResults, error: packingError } = useQuery(() => packingEndPoint.value)
    useShowError($toast, packingError)

    const harvestResultsGroup = computed(() => {
      if (!harvestResults) return []

      const filterByStartsWith = harvestResults.value.filter((result: IHarvestResult) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = result
        return (
          item.name.toLowerCase().startsWith(text) ||
          variety.name.toLowerCase().startsWith(text) ||
          size.name.toLowerCase().startsWith(text) ||
          quality.name.toLowerCase().startsWith(text)
        )
      })
      const filterByIncludes = harvestResults.value.filter((result: IHarvestResult) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = result
        return (
          !(
            item.name.toLowerCase().startsWith(text) ||
            variety.name.toLowerCase().startsWith(text) ||
            size.name.toLowerCase().startsWith(text) ||
            quality.name.toLowerCase().startsWith(text)
          ) &&
          (item.name.toLowerCase().includes(text) ||
            variety.name.toLowerCase().includes(text) ||
            size.name.toLowerCase().includes(text) ||
            quality.name.toLowerCase().includes(text))
        )
      })
      const filterResult = [...filterByStartsWith, ...filterByIncludes]

      const results = filterResult.map((harvest: IHarvestResult) => {
        return {
          ...harvest,
          harvestStems: harvest.stems,
          orderStems: harvest.orderedStems || 0,
          harvestBoxes: harvest.boxes || 0,
          orderBoxes: harvest.orderedBoxes || 0,
          orderedSpecialStems: harvest.orderedBoxes ? 0 : harvest.orderedStems
        }
      })

      const groupData =
        treeviewLevel.value === 2
          ? groupTwoLevel(
              results,
              ['harvestStems', 'orderStems', 'harvestBoxes', 'orderBoxes', 'orderedSpecialStems'],
              ['itemName']
            )
          : groupMultiLevel(results, [
              'harvestStems',
              'orderStems',
              'harvestBoxes',
              'orderBoxes',
              'orderedSpecialStems'
            ])

      return mappingStock(
        groupData,
        {
          assignments: assignments.value,
          packingResults: packingResults.value
        },
        treeviewLevel.value === 2,
        HARVEST_RESULT_DES
      ) as Array<MultiLevelProduct>
    })

    const historyHarvestData = computed(() => {
      if (!harvestResults) return []
      const harvestResultsOrderById = orderBy(harvestResults.value, 'id', 'desc')
      const filterByStartsWith = harvestResultsOrderById.filter((result: IHarvestResult) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = result
        return (
          item.name.toLowerCase().startsWith(text) ||
          variety.name.toLowerCase().startsWith(text) ||
          size.name.toLowerCase().startsWith(text) ||
          quality.name.toLowerCase().startsWith(text)
        )
      })
      const filterByIncludes = harvestResultsOrderById.filter((result: IHarvestResult) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = result
        return (
          !(
            item.name.toLowerCase().startsWith(text) ||
            variety.name.toLowerCase().startsWith(text) ||
            size.name.toLowerCase().startsWith(text) ||
            quality.name.toLowerCase().startsWith(text)
          ) &&
          (item.name.toLowerCase().includes(text) ||
            variety.name.toLowerCase().includes(text) ||
            size.name.toLowerCase().includes(text) ||
            quality.name.toLowerCase().includes(text))
        )
      })
      const filterResult = [...filterByStartsWith, ...filterByIncludes]

      const results = filterResult.map((harvest: IHarvestResult) => {
        return {
          ...harvest,
          harvestStems: harvest.stems,
          harvestBoxes: harvest.boxes || 0,
          orderStems: harvest.orderedStems || 0,
          orderBoxes: harvest.orderedBoxes || 0
        }
      })
      return results
    })

    const productData = computed(() => {
      if (!varieties.value || !sizes.value) return []

      const data = [] as Array<HarvestProductInfo>

      varieties.value.forEach((variety: Variety) => {
        if (treeviewLevel.value === 4) {
          const sizeList = sizes.value.filter(
            (size: Size) => size.sizeGroup.id === variety.sizeGroup?.id
          )
          const qualityList = qualities.value.filter(
            (quality: Quality) => quality.qualityGroup.id === variety.qualityGroup?.id
          )

          qualityList.forEach((quality: Quality) => {
            sizeList.forEach((size: Size) => {
              data.push({ item: variety.item, variety, size, quality })
            })
          })
        } else {
          data.push({
            item: variety.item,
            variety,
            size: null as unknown as Size,
            quality: null as unknown as Quality
          })
        }
      })

      const filterByStartsWith = data.filter((product: HarvestProductInfo) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = product
        return (
          item.name.toLowerCase().startsWith(text) ||
          variety.name.toLowerCase().startsWith(text) ||
          size?.name.toLowerCase().startsWith(text) ||
          quality?.name.toLowerCase().startsWith(text)
        )
      })
      const filterByIncludes = data.filter((product: HarvestProductInfo) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = product
        return (
          !(
            item.name.toLowerCase().startsWith(text) ||
            variety.name.toLowerCase().startsWith(text) ||
            size?.name.toLowerCase().startsWith(text) ||
            quality?.name.toLowerCase().startsWith(text)
          ) &&
          (item.name.toLowerCase().includes(text) ||
            variety.name.toLowerCase().includes(text) ||
            size?.name.toLowerCase().includes(text) ||
            quality?.name.toLowerCase().includes(text))
        )
      })
      const filterData = [...filterByStartsWith, ...filterByIncludes]

      const groupData =
        treeviewLevel.value === 2 ? groupTwoLevel(filterData) : groupMultiLevel(filterData)
      const orderDetails = (harvestResults.value || []).map((result: IHarvestResult) => {
        return { ...result, stems: result.orderedStems || 0, boxes: result.orderedBoxes }
      })

      return mappingStock(
        groupData,
        {
          orderDetails,
          harvestResults: harvestResults.value,
          assignments: assignments.value,
          packingResults: packingResults.value
        },
        treeviewLevel.value === 2,
        HARVEST_RESULT_DES
      ) as Array<MultiLevelProduct>
    })

    const searching = computed(() => {
      if (harvestResults.value && isValidating.value && allowSearchLoading.value) {
        return true
      }
      return false
    })

    const harvestSelectedState = computed(() => {
      const { rootIndex, firstIndex, secondIndex } = selectedLevel
      const dataGroup = showBy.value === 'HARVEST' ? harvestResultsGroup.value : productData.value

      const groupByItem = dataGroup[rootIndex as number] as MultiLevelProduct
      const { itemName } = groupByItem

      const varietyName = firstIndex !== null ? groupByItem.children[firstIndex].varietyName : null
      const qualitySizeName =
        secondIndex !== null
          ? groupByItem.children[firstIndex as number].children[secondIndex].qualitySizeName
          : null

      const results = harvestResults.value.filter((result: IHarvestResult) => {
        const item = result.item.name
        const variety = result.variety.name
        const qualitySize =
          `${result.quality?.name || ''}${result.size?.name || ''}` || root.$t('order.no_size')

        if (secondIndex !== null) {
          return item === itemName && variety === varietyName && qualitySize === qualitySizeName
        }

        if (firstIndex !== null) {
          return item === itemName && variety === varietyName
        }

        return item === itemName
      })
      const firstResult = results[0]

      return {
        item: firstResult.item,
        variety: firstIndex !== null ? firstResult.variety : null,
        size: secondIndex !== null ? firstResult.size : null,
        quality: secondIndex !== null ? firstResult.quality : null,
        result: null,
        ids: results.map((detail: IHarvestResult) => detail.id)
      }
    })

    const productSelectedState = computed(() => {
      const { rootIndex, firstIndex } = selectedLevel
      const groupByItem = productData.value[rootIndex as number] as MultiLevelProduct

      const { itemName } = groupByItem
      const { item } = varieties.value.find((va: Variety) => va.item.name === itemName)

      let variety = null
      if (firstIndex !== null) {
        const { varietyName } = groupByItem.children[firstIndex]
        variety = varieties.value.find((va: Variety) => va.name === varietyName)
      }

      return {
        item,
        variety,
        size: null,
        quality: null
      }
    })

    const createOrEditResult = (
      resultId: number | null = null,
      isDuplicated: boolean | null = false
    ): void => {
      $router
        .push({
          name: urlPath.HARVEST_RESULT_FORM.name,
          params: {
            resultId: resultId ? resultId.toString() : 'create',
            isDuplicated: isDuplicated ? '1' : '0'
          },
          query: {
            harvest_date: harvestDateTo.value,
            called: 'true'
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const replaceUrl = (): void => {
      const queryValue = searchInfo.value ? { search_info: searchInfo.value } : {}
      $router
        .replace({
          query: {
            auction_date: date.value,
            date_from: harvestDateFrom.value,
            date_to: harvestDateTo.value,
            show_by: showBy.value,
            called: 'true',
            ...queryValue
          }
        })
        .catch((e) => {
          console.log(e)
        })
      const latestSelectedDates = {
        auctionDate: date.value.toString(),
        packingDateRange: [dateFrom.value, dateTo.value]
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
    }

    const search = (): void => {
      endPoint.value = url.value
      assignEndPoint.value = assignUrl.value
      packingEndPoint.value = packingUrl.value
      allowSearchLoading.value = true

      replaceUrl()

      $store.commit('setHarvestPanelState', {})
      $store.commit('setProductPanelState', {})
      getAssignments()
    }

    const updatePanelState = (state: Record<string, unknown>): void => {
      $store.commit('setHarvestPanelState', state)
    }

    const updateProductPanelState = (state: Record<string, unknown>): void => {
      $store.commit('setProductPanelState', state)
    }

    const openActionSheet = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      selectedLevel.rootIndex = rootIndex
      selectedLevel.firstIndex = firstIndex
      selectedLevel.secondIndex = secondIndex

      showBottomSheet.value = true
    }

    const expandOrCollapseAll = (action: string) => {
      const argument = {
        data: showBy.value === 'HARVEST' ? harvestResultsGroup.value : productData.value,
        state: showBy.value === 'HARVEST' ? panelState.value : productPanelState.value,
        selected: selectedLevel
      }

      const state =
        action === 'EXPAND_ALL' ? getExpandPanelState(argument) : getCollapsePanelState(argument)
      const mutate = showBy.value === 'HARVEST' ? 'setHarvestPanelState' : 'setProductPanelState'

      $store.commit(mutate, state)
      showBottomSheet.value = false
    }

    const handleClickOrder = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      selectedLevel.rootIndex = rootIndex
      selectedLevel.firstIndex = firstIndex
      selectedLevel.secondIndex = secondIndex

      $store.commit('setHarvestOrderState', harvestSelectedState.value)
      showOrderDialog.value = true
    }

    const handleClickDetail = (id: number): void => {
      const harvest = harvestResults.value.find((result: IHarvestResult) => result.id === id)

      $store.commit('setHarvestOrderState', {
        item: harvest.item,
        variety: harvest.variety,
        size: harvest.size,
        quality: harvest.quality,
        result: harvest,
        ids: [id]
      })
      showOrderDialog.value = true
    }

    const handleClickProduct = (product: HarvestProductInfo): void => {
      $store.commit('setProductState', {
        item: product.item,
        variety: product.variety,
        size: product.size,
        quality: product.quality
      })
      createOrEditResult()
    }

    const addHarvestResult = (): void => {
      const product =
        showBy.value === 'HARVEST' ? harvestSelectedState.value : productSelectedState.value
      handleClickProduct(product as HarvestProductInfo)
    }

    const addOrder = (): void => {
      $store.commit('setHarvestOrderState', harvestSelectedState.value)
      $router
        .push({
          name: urlPath.ORDER_DETAIL_FORM.name,
          params: {
            orderId: '0',
            detailId: '0'
          },
          query: { called: 'true' }
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const handleSearchInput = debounce((value: string) => {
      allowProductLoading.value = true
      if (value !== searchInfo.value) {
        searchInfo.value = value

        delay(
          () => {
            allowProductLoading.value = false
            replaceUrl()
          },
          100,
          true
        )
      }
    }, 500)
    onMounted(async () => {
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        ) &&
        !isFromCalendar
      )
        await getNextAuctionDate()

      await getSettingLevel()
      await getAssignments()
      const showValueState = $store.state.harvest.showValue
      if (Object.keys(showValueState).length) {
        isShowByBox.value = showValueState.isShowByBox
        isShowRemaining.value = showValueState.isShowRemaining
        isShowTotal.value = showValueState.isShowTotal
      } else {
        getSettingDefault()
      }
    })

    const setDate = (start: string, end: string) => {
      harvestDateFrom.value = start
      harvestDateTo.value = end
    }

    const reload = async (enableScrollToPreviousPosition: boolean) => {
      // get position before reload
      const positionY = window.scrollY

      await getSettingLevel()
      try {
        const data = await Promise.all([
          api.get(`${packingUrl.value}`),
          api.get(`${url.value}`),
          api.get(`${assignUrl.value}`)
        ])
        const [{ data: packingResultsData }, { data: harvestResultsData }, { data: assignsData }] =
          data
        packingResults.value = toCamelCase(packingResultsData)
        assignments.value = toCamelCase(assignsData)
        harvestResults.value = toCamelCase(harvestResultsData)

        // scroll to previous position
        if (enableScrollToPreviousPosition) {
          window.scrollTo(0, positionY)
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }
    const deleteResult = async (): Promise<boolean> => {
      try {
        await api.delete(`${endpoints.HARVEST_RESULT}${selectedHarvestId.value}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      }
      return false
    }

    const confirmDelete = async (action: string): Promise<void> => {
      showDelete.value = false
      let success = false
      if (action === 'delete') {
        success = await deleteResult()
      }
      if (success) {
        reload(true)
      }
    }

    return {
      dateRange,
      showBy,
      selectItems,
      loading,
      productLoading,
      searching,
      search,
      searchInfo,
      selectedHarvestId,
      createOrEditResult,
      replaceUrl,
      harvestResultsGroup,
      panelState,
      hideButtons,
      updatePanelState,
      isShowByBox,
      isShowTotal,
      isShowRemaining,
      isShowSetting,
      updateSetting,
      showBottomSheet,
      openActionSheet,
      expandOrCollapseAll,
      harvestDateTo,
      harvestDateToModal,
      harvestDateFrom,
      harvestDateFromModal,
      auctionDateModal,
      showOrderDialog,
      handleClickOrder,
      handleClickDetail,
      productData,
      historyHarvestData,
      productPanelState,
      updateProductPanelState,
      handleClickProduct,
      selectedLevel,
      addHarvestResult,
      addOrder,
      handleSearchInput,
      setDate,
      date,
      getAssignments,
      reload,
      treeviewLevel,
      confirmDelete,
      showDelete
    }
  }
})

export default HarvestResult
